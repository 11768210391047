import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./KennySharf.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import KennySharfPhoto1 from "../../../../res/Photos site/Kenny Sharf/birdman-kennyscharf-am-14.jpg"
import KennySharfPhoto2 from "../../../../res/Photos site/Kenny Sharf/DIOR_KENNY_SCHARF_portrait.jpg"
import KennySharfPhoto4 from "../../../../res/Photos site/Kenny Sharf/Kenny Scharf Studio.jpg"
import KennySharfPhoto5 from "../../../../res/Photos site/Kenny Sharf/2.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/scharf/portrait.jpg'
import PastPresent1 from "../../../../res/scharf/past-present-1.jpg"
import PastPresent2 from "../../../../res/scharf/past-present-2.png"
import PastPresent3 from "../../../../res/scharf/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "KennySharf",
  name: "KennySharf",
  description:"Né à Los Angeles en 1958, Kenny Scharf est un artiste américain qui a commencé sa carrière dans les années 1980. Il étudie à l’école des arts visuels de New York dont il est diplômé cette même année. Il fait partie des artistes qui, au début des années 1980, créent une connexion entre le milieu de l'art contemporain et celui du graffiti, comme Jean-Michel Basquiat ou Keith Haring. Il expose à la Fun Gallery en 1981 et à la galerie Tony Shafrazi en 1984. Depuis, Kenny Scharf a montré ses oeuvres dans le monde entier et ses peintures font partie des collections permanentes de nombreuses institutions artistiques, comme le Guggenheim Museum, le Whitney Museum of American Art ou encore le Stedelijk Museum d'Amsterdam. Utilisant en toute liberté les images de la culture populaire et notamment télévisuelle, Scharf développe une oeuvre gaie et colorée assez proche des préoccupations de la figuration libre française avec Robert Combas ou Hervé di Rosa. Kenny Scharf est par ailleurs extrêmement sollicité pour la réalisation de collaborations avec des maisons de haute couture telles que Dior.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de KennySharf.",
  photos: [
    { src: PastPresent1, name: "KennySharf" },
    { src: PastPresent2, name: "KennySharf" },
    { src: PastPresent3, name: "KennySharf" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: [
    "« Part of what I do and what I want to do is I want to bring art into the everyday life. If you can take ordinary just walking in the street and you're confronted by something, that might change your day - it might inspire you »",
    "« I’m constantly doing new stuff. I’m always excited. I'm a one-man tornado »",
  ]
};

const KennySharf = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>KENNY SCHARF <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1958</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Kenny Scharf, Bestest ever !, Honor Fraser, 18 juin - 10 septembre</p>
          <p>- Kenny Scharf, Woodz’n thingz, David Totah, 21 avril - 25 juin.</p>
          <p>- Kenny Scharf, Scharf Shak, Baik art Seoul, Seoul, South Korea.</p>
          <p>- Kenny Scharf, Almine Rech Shanghai, Huangpu Shanghai, China.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Kenny Scharf: Vaxi Nation, Almine Rech, Paris, France.</p>
          <p>- Kenny Scharf: Phlatskreen Trod, Honor Fraser, Culver city, Los Angeles, CA, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Kenny Scharf: Moodz, Jeffrey Deich, Los Angeles, California, USA.</p>
          <p>- Kenny Scharf: Dystopian painting, Almine Rech New York, Upper East Side, NY, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Kenny Scharf: Anxiously Optimistic, BAik Art, Seoul, Jongro-Gee, Seoul South Korea.</p>
          <p>- Kenny Scharf: Optimistically Melting!, Honor Fraser, Culver city, Los Angeles, CA, USA.</p>
          <p>- Kenny Scharf: Blue Blood, Totah, Lower East Side, NY, USA.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Kenny Scharf, David Klein Gallery Detroit, Detroit, Michigan, USA.</p>
          <p>- Inner and Outer space, Leila Haller Gallery, New York, upper east side, New York, NY, USA.</p>
          <p>- Kenny Scharf: Blobz, Opera Gallery, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Kenny Scharf: Totemoh, Art in the parks, New York, USA.</p>
          <p>- Kenny Scharf: Blox and Box, Honor Fraser, Culver city, Los Angeles, CA, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Kenny Scharf, Nassau County Museum of Art, Roslyn, New York, USA.</p>
          <p>- Kenny Scharf,Hammer Museum, Westwood, Los Angeles, California, USA.</p>
          <p>- Kenny Scharf: SCHOW, Fredric Snitzer Gallery, Downtown Miami, Miami, Florida, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Born Again, Honor Fraser, Culver city, Los Angeles, Ca, USA.</p>
          <p>- Kenny Scharf: Face Prints, 57th Street, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Kenny Scharf: Pop Renaissance, Honor Fraser, Culver city, Los Angeles, California, USA.</p>
          <p>- Kenny Scharf: Kolors, Kasmin gallery, Chelsea, New York, USA.</p>
          <p>- Amerikulture, Kenny Scharf & Tseng Kwong Chi.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Kenny Scharf: Naturafutura, Kasmin gallery, Chelsea, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Kenny Scharf & Dearraindrop: Hot Glue Hullabaloo The Hole, 312st. Soho, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Kenny Scharf: Barberadis, Honor Faster, Culver city, Los Angeles, California, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Kenny Scharf: Superdeluxe, Waddington Custot, London, UK.</p>
          <p>- Four Friends, Kenny Scharf, Tony Shafrazi Gallery, Chelsea, UK.</p>
          <p>- Kenny Scharf: Totemikon, Kasmin gallery, Chelsea, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Kenny Scharf, Kasmin gallery, Chelsea, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Kenny Scharf: "Superpop & Closet #24", Kasmin gallery, Chelsea, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Kenny Scharf, Tony Shafrazi Gallery, Chelsea, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Kenny Scharf, "New Paintings & Sculptures", Tony Shafrazi Gallery, Chelsea, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Kenny Scharf, New Paintings, Tony Shafrazi Gallery, Chelsea, UK.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- If Tomorrow Comes, Bravinlee Programs, Chelsea, New York, USA.</p>
          <p>- Unicum, Anders Wahlstedt Fine Art, Chelsea, New York, USA.</p>
          <p>- ONLINE: Pride, Ikon LTD, Santa Monica, California, USA.</p>
          <p>- Arrival of Spring, Ikon LTD, Santa Monica, California, USA.</p>
          <p>- Retrouvailles, Almine Rech, Paris, France.</p>
          <p>- ONLINE: Art to wear, BG Gallery, Santa Monica, California, USA.</p>
          <p>- Concrete to canvas, West Chelsea Contemporary, Austin, TX, USA.</p>
          <p>- Unicum a group print show, Anders Wahlstedt Fine Art, New York, USA.</p>
          <p>- Finally Home, Maune Contemporary, Santa Barbara, CA, USA.</p>
          <p>- 2022 New Paintings Photographs Prints Sculptures, HK Art Advisory + Projects</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Un Hiver à Paris, Almine Rech, Paris, France.</p>
          <p>- On the bright side, Opera Gallery, Paris, France.</p>
          <p>- Figurative still life objectivity, Rosenfeld Gallery, Miami, California, USA.</p>
          <p>- Looney Tunes, Corridor Contemporary, Tel Aviv, Israël.</p>
          <p>- Mr. Brainwash and Kitzbuhel Winter Crossover, Galerie Frank Fluegel, Austria.</p>
          <p>- Colorful, QG Gallery, Knokke-Zoute, Belgium.</p>
          <p>- We are All in This Together, Galerie Frank Fluegel, Nuremberg, Germany.</p>
          <p>- Prints and multiples, HK Art Advisory + Projects, Los Angeles, CA, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Third Dimension: Works from the Brant Foundation, The Brant Foundation art study center, New York, NY, USA.</p>
          <p>- The Shadow, Totah, Lower east side, New York,USA.</p>
          <p>- Happy! NSU art Museum Fort Lauderdale, Fort Lauderdale, Florida, USA.</p>
          <p>- For the love of art, Rosenfeld Gallery, Miami, California, USA.</p>
          <p>- American Icons - Legacy, Opera Gallery, Paris France.</p>
          <p>- Graffiti Innovators, Rosenfeld Gallery, Miami, California, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Off Menu: Contemporary Art About Food, Belford Gallery, Walnut Creek, California, USA.</p>
          <p>- Beyond the Cape! omics and Contemporary Art, Boca Ration Museum of Art, Boca Ration, Florida, USA.</p>
          <p>- Hysterical presented by Gary Lard, Phillips London, Mayfair, London, UK.</p>
          <p>- Five, Baik Art Los Angeles, LA, USA.</p>
          <p>- That Eighties Show, Nassau county Museum of Art, Roselyn, New York, USA.</p>
          <p>- Fatherhood, Over The Influence, Hong Kong, Central, HK, HK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- The art of collaborations Venus over Manhattan, Upper East Side, New York, USA.</p>
          <p>- Polarities, Totah, Lower East Side, NY, USA.</p>
          <p>- Exemplary Bodies, Leila Haller Gallery, New York, upper east side, New York, NY, USA.</p>
          <p>- Moonage virtual reality, Phoenix Art Museum, Phoenix, Arizona, USA.</p>
          <p>- Summer Group Show, Leila Haller Gallery, New York, upper east side, New York, NY, USA.</p>
          <p>- Dancing Goddesses, Dio Horia, Mykonos, Greece.</p>
          <p>- Zeitgeist: The art scene of Teenage Basquiat, HOWL! happening, New York, USA.</p>
          <p>- Stand, Still,... Still: A Still Life Show Part II, Allauene Gallery, New York, NY, USA.</p>
          <p>- Club 57: Lost Flyers 1979-83, Alden projects, NY, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Inner and Outer space, Jeffrey Deith, New York, Soho, NY, USA.</p>
          <p>- Animal Farm, The Brant Foundation ARt StudyCenter, Greenwich, Connecticut, USA.</p>
          <p>- Outside in: The downtown Hollywood Mural project exhibition, art and culture center of Hollywood, Hollywood, Florida, USA.</p>
          <p>- Fast Forward: Paintings from the 1980’s, Whitney Museum of American Art, Greenwich village, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Cosmic connections, Totah, Lower east side, New York, USA.</p>
          <p>- A selection of works from the 1980’s, Vito Schnabel Gallery, ST.Moritz, Switzerland.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- The Shells Seashells by The Seashore, Eric Firestone Gallery, East HAmpton, Ny, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Urban Theater, New York art in the 1980s, Modern Art Museum of Fort Worth, Fort Worth, Texas, USA.</p>
          <p>- Positively Marvillainous, Eric Firestone Gallery, East Hampton, New York, USA.</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Tony Shafrazi Gallery, Art Basel 2012, New York, NY, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Kenny Scharf: Club 57 & Friends, The Dorian Grey Gallery, New York, USA.</p>
          <p>- Nose Job, Eric Firestone Gallery, East Hampton, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Art Shack, Laguna Art Museum, Laguna Beach, California, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Stages Lance Armstrong Foundation launch a global art exhibition to raise funds and awareness in the fight against cancer, Galerie Perrotin, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- KBG Project - Summer 2008, Kevin Bruk Gallery, Downtown Miami, Miami, Florida, USA.</p>
          <p>- Jewelry: Picasso to Kenny Scharf, Neuhoff Edelman Gallery, 57th Street, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p id="end">- Four Friends, Tony Shafrazi Gallery, Chelsea, UK.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default KennySharf;