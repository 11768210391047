// extracted by mini-css-extract-plugin
export var ArtistDescription = "KennySharf-module--ArtistDescription--zzUXS";
export var ArtistInfos = "KennySharf-module--ArtistInfos--wFgN5";
export var ButtonWrapper = "KennySharf-module--ButtonWrapper --h09rO";
export var CardWrapper = "KennySharf-module--CardWrapper--cpkht";
export var CarrouselWrapper2 = "KennySharf-module--CarrouselWrapper2--r3VTh";
export var Citations = "KennySharf-module--Citations--b3E6F";
export var DescriptionWrapper = "KennySharf-module--DescriptionWrapper--5FscL";
export var ImageWrapper = "KennySharf-module--ImageWrapper--kcib+";
export var LinkWrapper = "KennySharf-module--LinkWrapper--qumJ+";
export var MobileProtrait = "KennySharf-module--MobileProtrait--sjTkK";
export var More = "KennySharf-module--More--TSvtZ";
export var MoreButton = "KennySharf-module--MoreButton--1f8Da";
export var NameWrapper = "KennySharf-module--NameWrapper--x1XUX";
export var PdpWrapper = "KennySharf-module--PdpWrapper--UJuv8";
export var PhotosWrapper = "KennySharf-module--PhotosWrapper--RxZ76";
export var ProfilWrapper = "KennySharf-module--ProfilWrapper--Xzfq3";
export var TitleWrapper = "KennySharf-module--TitleWrapper---jNE1";
export var Wrapper = "KennySharf-module--Wrapper--Ajqt5";